import React from "react"
import indexStyles from "../styles/index.module.scss"

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../utils/fontawesome"

export default function Home() {
  return (
    <>
      <main>
        <div className={indexStyles.content}>
          <h1>Under Development.</h1>
          <span className={"hline " + `${indexStyles.line}`}></span>
          <p>I&apos;m currently developing this website using GatsbyJS.</p>
          <p>To track progress visit my GitHub or <a href="https://trello.com/b/VCz8ftMj/osheadev">my Trello</a>.</p>
          <div className={indexStyles.socialIcons}>
            <a href="https://github.com/the-glaedar">
              <FontAwesomeIcon icon={["fab", "github"]} />
            </a>
            <a href="https://www.instagram.com/hamish.leg/">
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
            <a href="https://www.linkedin.com/feed/">
              <FontAwesomeIcon icon={["fab", "linkedin"]} />
            </a>
            <a href="mailto:hamish@oshea.dev">
              <FontAwesomeIcon icon={["fa", "envelope"]} />
            </a>
          </div>
        </div>
      </main>
    </>
  )
}
